import { doc, getDoc } from "firebase/firestore";
import { createContext, useContext, useState } from "react";
import { auth, db } from "../config/firebaseSetup";

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
    window.location = "/";
  };

  const getUserData = async () => {
    const currentUser = auth.currentUser;
    // console.log(currentUser);
    try {
      const userDocRef = doc(db, "users", currentUser.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        // console.log("No such document!");
        setUserData({});
      }
    } catch (error) {
      setUserData({});
    }
  };

  const showLoginModalHandler = () => {
    setShowLoginModal(true);
    setShowSignUpModal(false);
  };
  const closeAuthModalHandler = () => {
    setShowLoginModal(false);
    setShowSignUpModal(false);
  };

  const showSignUpModalHandler = () => {
    setShowLoginModal(false);
    setShowSignUpModal(true);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        userData,
        getUserData,
        showLoginModalHandler,
        closeAuthModalHandler,
        showSignUpModalHandler,
        showLoginModal,
        showSignUpModal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
