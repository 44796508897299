import React, { useEffect, useState } from "react";
import { auth, db } from "../config/firebaseSetup";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const ReservePrice = ({ auction }) => {
  const currentUser = auth.currentUser;
  const [isProcessingAutoBid, setIsProcessingAutoBid] = useState(false);

  // Handle first bid auto-bidding logic
  useEffect(() => {
    const handleFirstBidReservePrice = async () => {
      // Only proceed if there is an auction and current user
      if (!auction || !currentUser || isProcessingAutoBid) return;

      const bidHistory = auction.bidHistory || [];

      // Check if this is the first bid and it belongs to current user
      if (bidHistory.length === 1) {
        const firstBid = bidHistory[0];

        // Verify this is the current user's bid
        if (firstBid.userEmail === currentUser.email) {
          // Check if optional bid amount meets or exceeds reserve price
          if (
            firstBid.optionalBidAmount >= Number(auction.auctionReservePrice)
          ) {
            setIsProcessingAutoBid(true);

            try {
              // Get fresh auction data
              const auctionRef = doc(db, "Auction", auction.id);
              const auctionDoc = await getDoc(auctionRef);

              if (!auctionDoc.exists()) {
                throw new Error("Auction not found");
              }

              // Create new bid at reserve price
              const newBid = {
                ...firstBid,
                bidAmount: Number(auction.auctionReservePrice),
                timestamp: new Date().toISOString(),
              };

              // Update auction with new bid
              await updateDoc(auctionRef, {
                bidHistory: [...bidHistory, newBid],
                highestBid: Number(auction.auctionReservePrice),
                isReservePriceMet: true,
                lastBidderEmail: firstBid.userEmail,
                lastBidderId: firstBid.userId,
              });

              toast.success("Auto-bid placed to meet reserve price");
            } catch (error) {
              console.error("Error processing reserve price auto-bid:", error);
              toast.error("Error processing automatic bid");
            } finally {
              setIsProcessingAutoBid(false);
            }
          }
        }
      }
    };

    handleFirstBidReservePrice();
  }, [auction, currentUser, isProcessingAutoBid]);

  // Return null if no current user
  if (!currentUser) {
    return null;
  }

  // Only show reserve price status for users who have bid
  if (auction && auction.bidHistory) {
    const userBid = auction.bidHistory.some(
      (userBid) => userBid.userEmail === currentUser.email
    );

    if (userBid) {
      return (
        <div
          className="reservePrice"
          style={{
            marginTop: "1rem",
            color:
              auction?.highestBid < auction.auctionReservePrice
                ? "red"
                : "green",
          }}
        >
          {isProcessingAutoBid ? (
            <span style={{ color: "blue" }}>
              Processing auto-bid to meet reserve price...
            </span>
          ) : auction?.highestBid < auction.auctionReservePrice ? (
            "Reserve Price Not Met"
          ) : null}
        </div>
      );
    }
  }

  return null;
};

export default ReservePrice;
